
import { Component, Vue, Prop } from 'vue-property-decorator'
import { GreedGear, GreedItem } from '@/interfaces/loot'

@Component
export default class GreedRaidModal extends Vue {
  @Prop()
  entry!: GreedGear

  @Prop()
  save!: (list: GreedItem | null) => void

  select(list: GreedItem | null): void {
    this.save(list)
    this.$emit('close')
  }
}
