var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v(" Changelog ")]),_c('div',{staticClass:"card-header-icon"},[_c('a',{staticClass:"icon",on:{"click":() => { this.$emit('close') }}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])])]),_c('div',{staticClass:"card-content content"},[_c('h2',{staticClass:"has-text-primary subtitle"},[_vm._v(_vm._s(_vm.version))]),_vm._m(0),_c('p',[_vm._v("Improved Loot Solver algorithm to handle newfound edgecase.")]),_c('p',[_vm._v("Also fixed silly bug in the code that was introduced when fixing the previous Loot Solver bug!")]),_c('h2',{staticClass:"has-text-primary subtitle"},[_vm._v(_vm._s(_vm.version.split('.')[0])+".2")]),_vm._m(1),_c('p',[_vm._v("Quick extra push today to deliver a bugfix to a long hidden bug during Team deletion. Sorry for the inconvenience but thank you for catching it!")]),_c('p',[_vm._v("Thankfully it wasn't an issue for deleting anything, just caused an error at the end of the endpoint call!")]),_c('h2',{staticClass:"has-text-primary subtitle"},[_vm._v(_vm._s(_vm.version.split('.')[0]))]),_vm._m(2),_c('p',[_vm._v("Fixed a bug during the Character Verification process where it couldn't do cleanup, which first occurred yesterday.")]),_vm._m(3),_c('p',[_vm._v("If anyone would like to share feedback / give ideas on this matter, please let me know on Github or in the Discord, or by using the new addition below. Hopefully this is an acceptable solution!")]),_vm._m(4),_vm._m(5),_c('p',[_vm._v("I wasn't planning on releasing this so soon but I wanted the bugfix out ASAP and this was already in the codebase :D")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Loot Solver Algorithm Improvement / Bugfix "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Team Delete Bugfix "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Character Verify Bugfix "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v("For an explanation of the bug and what was done to fix it; "),_c('ul',[_c('li',[_vm._v("Previously, unverified Characters could not be used to make BIS Lists or join Teams or anything, so when you verified a Character the system would delete all the unverified versions of the same Character by Lodestone ID.")]),_c('li',[_vm._v("Now however, unverified Characters can be used for a week before everything gets deleted, but this caused a bug in the cleanup process where if an unverified version had a BIS List it couldn't be deleted.")]),_c('li',[_vm._v("Also, any proxies using the same Character get merged into the verified Character, consolidating all BIS Lists and Team Membership into the verified Character.")]),_c('li',{staticClass:"has-text-primary"},[_vm._v("The fix I have implemented is to do this consolidation for un-verified Characters belonging to the same User as well, and leaving unverified versions owned by other Users to be deleted after the week deadline.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Feedback Form "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Added a little Feedback widget for another mechanism of gathering feedback for people not on Github or don't want to join the Discord. "),_c('ul',[_c('li',[_vm._v("I need to upgrade the website UI from using Vue 2 to Vue 3; I'm aiming to start on this in the downtime after 7.2 drops.")]),_c('li',[_vm._v("I intend to keep the site as close as possible as how it is to use currently so if you like how it works then don't worry!")]),_c('li',[_vm._v("However, if there are bugs/issues that I haven't caught, or if there is anything you think is weird or difficult to use, please let me know!")]),_c('li',[_vm._v("I want to make this site as good as I can, and I want you all to be as happy as possible using it as well, so please help me if there is anything I should know!")])])])
}]

export { render, staticRenderFns }