import { render, staticRenderFns } from "./per_fight_member_select.vue?vue&type=template&id=0be2441f&scoped=true"
import script from "./per_fight_member_select.vue?vue&type=script&lang=ts"
export * from "./per_fight_member_select.vue?vue&type=script&lang=ts"
import style0 from "./per_fight_member_select.vue?vue&type=style&index=0&id=0be2441f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be2441f",
  null
  
)

export default component.exports